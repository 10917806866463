import React from 'react';
import { Carousel } from 'react-responsive-carousel'; 
import Css from '../assets/CSS.jpeg';
import freeCodeCamp from '../assets/freeCodeCamp.png';
import Html from '../assets/HTML.jpeg';
import Js from '../assets/JS.jpeg';
import Python from '../assets/python.png';
import Wordpress from '../assets/WORDPRESS.jpeg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/Carousel.css';

const ImageCarousel = () => {
  return (
    <div className="carousel-wrapper"> 
      <Carousel 
        showThumbs={false} 
        autoPlay={true} 
        infiniteLoop={true} 
        showStatus={false}
        emulateTouch={true}
      >
        <div>
          <img src={Css} alt="CSS" />
        </div>
        <div>
          <img src={freeCodeCamp} alt="freeCodeCamp" />
        </div>
        <div>
          <img src={Html} alt="HTML" />
        </div>
        <div>
          <img src={Js} alt="JavaScript" />
        </div>
        <div>
          <img src={Python} alt="Python" />
        </div>
        <div>
          <img src={Wordpress} alt="WordPress" />
        </div>
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
