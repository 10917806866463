import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import EmailIcon from '@mui/icons-material/Email'
import ImageCarousel from '../components/Carousel'
import '../styles/Home.css'

function Home() {
  return (
    <div className='home'>
      <div className="about">
        <h2>Hey There, Welcome to My Website ! </h2> 
        <h2>I am Sudesh Raj Bajracharya</h2>
        <div className="prompt">
          <h3>Motivated & Passionate Junior Front-End Developer.</h3>
          <LinkedInIcon />
          <EmailIcon />
        </div>
      </div>
      <div className="skills">
        <h1>My Skills & Certifications.</h1>
        <ImageCarousel />
      </div>
    </div>
  )
}

export default Home