import React from 'react'
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import SchoolIcon from '@mui/icons-material/School'
import WorkIcon from '@mui/icons-material/Work'


function Experience() {
  return (
    <div className='experience'>
      <VerticalTimeline lineColor='#3e497a'>
      <VerticalTimelineElement 
          className='vertical-timeline-element--education' 
          date='March 2020 - October 2022'
          iconStyle={{background: '#3e497a', color:'#fff' }}
          icon={<SchoolIcon />}
        >
          <h3 
            className='vertical-timeline-element-title'
          >Australian Institute of Higher Education</h3>
          <p>Bachelors of Business Information System</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className='vertical-timeline-element--work'
          date='July 2023 - September 2023'
          iconStyle={{background: '#e9d35b', color:'#fff' }}
          icon={<WorkIcon />}>
            <h3 
            className='vertical-timeline-element-title'
          >DigiPearl</h3>
          <p>Junior Front-End Developer Internship</p>
        </VerticalTimelineElement>
      

        <VerticalTimelineElement
          className='vertical-timeline-element--work'
          date='October 2023 - Present'
          iconStyle={{background: '#e9d35b', color:'#fff' }}
          icon={<WorkIcon />}>
            <h3 
            className='vertical-timeline-element-title'
          >DigiPearl</h3>
          <p>Junior Front-End Developer</p>
        </VerticalTimelineElement>
      

        <VerticalTimelineElement
          className='vertical-timeline-element--education'
          date='February 2024 - October 2024'
          iconStyle={{background: '#3e497a', color:'#fff' }}
          icon={<SchoolIcon />}>
            <h3 
            className='vertical-timeline-element-title'
          >QIBA, Sydney Campus</h3>
          <p>The ACS Professional Year Program – ICT</p>
        </VerticalTimelineElement>
        
      </VerticalTimeline>
    </div>
  )
}

export default Experience