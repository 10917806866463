import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'
import EmailIcon from '@mui/icons-material/Email'
import '../styles/Footer.css'

function Footer() {
  return (
    <div className='footer'>
      <div className='socialMedia'>
        <LinkedInIcon />
        <FacebookIcon />
        <EmailIcon />
      </div>
      <p>&copy; 2024 Sudeshbajracharya.com</p>
    </div>
  )
}

export default Footer